@import '../../common/variables.scss';
@import '../../common/mixins.scss';
// STYLESHEET USED FOR CATEGORY PAGE AND USER DEFINED CATEGORY PAGE


.categoryList {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid gainsboro;
  padding-bottom: 7px;
}

.category-wrapper {
  @include flex-col;
  position: relative;
  width: 80%;
  background-color: $white;
  padding: 0px 23px;
  min-height: 100vh;
  background-color:$white;
  border-right: 1px solid gainsboro;
  @media (max-width: 1500px) {
    width: 100%;
  }
  @include max-breakpoint-tablet {
    width: 100%;
    border: none;
    border-radius: unset;
  }
}

.category-header {
  @include max-breakpoint-tablet {
    @include flex-row();
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }

  .follow-btn {
    max-width: 105px;
    max-height: 30px;
    background-color: $gainsboro;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    box-shadow: none;
    &:hover {
    background-color: $white;
    border: 1px solid $color-primary;
  }
  
  }
}

.ad-container {
  width: 20%;
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 1500px) {
    width: auto;
  }
}

.ad-placeholder-category {
  width: 300px;
  height: 600px;
  position: fixed;
  @media (max-width: 1500px) {
    display: none;
  }
}

.ad-placeholder-category::before {
  content: "ADVERTISEMENT";
  color:white;
  font-size: 10px;
  left: 88%;
  top: 190px;
  position: fixed;
}

.ad-placeholder-category-mobile {
  width: 320px;
  height: 80px;
  margin: auto;
  align-self: center;
}

.ad-placeholder-category-mobile::before {
  content: "ADVERTISEMENT";
  color:grey;
  font-size: 10px;
  left: 35%;
  position: relative;
}

// css classes used for placholders only
.grid-placeholder {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
}
.grid-placeholder >.card-container {
  width: 330px;
  margin-bottom: 15px;
}

.mobile-grid-placeholder {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-height: 100vh;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
.grid-container >.card-container {
  margin: 5px;
  width: 100%;
}