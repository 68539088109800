@import url("https://use.typekit.net/fqm3yjm.css");
@import '/src/common/mixins.scss';
@import '/src/common/variables.scss';


  .logo {
    width: 60px;
    @include min-breakpoint-tablet {
    width: 260px;
    height: 27px;
    }
  }
    .left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rightText {
    @include min-breakpoint-tablet {
      @include button;
      height: 45px;
      padding: 10px 30px;
    }
  }
    .profileName {
      fill: $color-primary;
      border-radius: 50%;
      background-color: $color-primary;
      color: $color-primary-contrast;
      min-width: 40px;
      height: 40px;
      font-size: $size-20;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: bold;
  }



.css-eglki6-MuiLinearProgress-root {
  height: 20px !important
}

#spec1 , #spec3 {
  text-decoration: underline;
  font-weight: 900;
}
#spec2, #spec4, #spec5 {
  font-weight: 900;
}
.logout {
  color: white;
  background-color: black;
}

.list-item {
  color: $color-text-secondary;
}