@import '/src/common/mixins.scss';
@import '/src/common/variables.scss';


.search--layout {
  width: 100%;
  @include flex-row();
  height: 100%;
}

.search--wrapper {
  @include flex-col();
  width: 75%;
  min-height: 100vh;
  position: relative;
  padding: 27px 27px 0px 27px;
  border-right: 1px solid $gainsboro;
  gap: 15px;

  @media (max-width: 1500px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 23px 0px 23px;
  }
}

.ad--wrapper {
  width: 25%;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 1500px) {
    width: auto;
  }
}

.search--header {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @include max-breakpoint-tablet {
    height: 135px;
    gap: 0px;
  }
}

.search--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .follow-btn {
    max-width: 105px;
    max-height: 30px;
    background-color: $gainsboro;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    &:hover {
      background-color: $white;
      border: 1px solid $color-primary;}
  }

    .follow-btn {
      max-width: 105px;
      max-height: 30px;
      background-color: $gainsboro;
      display: inline-flex;
      padding: 5px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      box-shadow: none;
      &:hover {
        background-color: $white;
        border: 1px solid $color-primary;}
    }
}



.search--info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  @include max-breakpoint-tablet {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin-top: 0px;
  }

  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    @include max-breakpoint-tablet {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3px;
    align-items: flex-start;
    }
  }
}

.results--wrapper {
  width: 100%;
  @include flex-col;
  gap: 20px;
}

.missing--search {
  width: 100%;
  height: 100vh;
  @include flex-col();
  flex-direction: column;
  justify-content: start;
  gap: 50px;
  @include max-breakpoint-tablet {
    gap: 20px;
  }

  .loupe--container {
    @include flex-col;
    margin: 120px 120px 0px 120px;
    gap: 20px;
    @include max-breakpoint-tablet {
      margin: 10px 30px;
      gap: 10px;
    }
  }

  .loupe{
    max-width: 80px;
    max-height: 80px;
  }

  .text--container {
    @include flex-col;
    margin: 0px 120px;
    gap: 17px;
    @include max-breakpoint-tablet {
      margin: 0px 35px;
      gap: 10px;
    }
  }
}

.search--card {
  width: 100%;
  z-index: 1;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex-direction: row;
  padding: 8px 0px;
  @include max-breakpoint-mobile-max {
    height: 400px;
    flex-direction: column;
    padding: 0px;
    gap: unset;
  }

  .left-col {
    min-width: 330px;
    height: inherit;
    padding: 8px 0px;
    @include max-breakpoint-mobile-max {
      width: 100%;
    }
  }

  .right-col {
    width: 100%;
    height: inherit;
    padding: 8px 0px;
  }

  .search--img-container {
    width: 330px;
    height: auto;
    max-height: 190px; 
    object-fit: cover;
    border-radius: 10px;
    @include max-breakpoint-mobile-max {
      width: 100%;
    }
  }

  .search--details-container {
    @include flex-col();
    width: 100%;
    height: 100%; 
    align-items: flex-start;
    gap: 5px;
    @include max-breakpoint-laptop {
      gap: unset;
    }

    .publication--details {
      @include flex-row;
      align-items: center;
      height: 40px;
      @include max-breakpoint-tablet {
        height: 35px;
        margin: 8px 0px;
      }
    }


    .search--logo {
      display: flex;
      align-self: center;
      border-radius: 50%;
      max-width: 25px;
      max-height: 25px;
      margin-right: 5px;
    }

    .search--content {
      @include flex-col();
      width: 100%;
      height: 100%;
      overflow: auto;
      text-decoration: none;
      gap: 10px;
      @include max-breakpoint-laptop {
        gap:5px;
      }
    }

    .search--toolbar {
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: 17px;
      @include max-breakpoint-tablet {
        height: 35px;
      }
      @include max-breakpoint-mobile-max {
        height: 35px;
        justify-content: space-between;
      }
    }
  }
}
