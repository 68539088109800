$breakpoint-mobile: 768px;

.section-page {
  .desktop {
    .card-container {
      min-width: 318px;
      .react-card-flip {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .section-page {
    .list {
      height: unset;
    }
    .card-container {
      min-width: 306px;
    }
  }
}