
$breakpoint-mobile: 768px;

.iframebody {
  margin-top: 80px;
   background-color: rgb(255, 255, 255);
}

@media (max-width: $breakpoint-mobile) {
  .iframebody {
    margin-top: 0px;
    background-color: rgb(255, 255, 255);
  }
}