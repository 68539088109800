@import '../../../common/variables.scss';
@import '../../../common/mixins.scss';

.trending-bar {
  background-color: $color-background;
  height: 40px;
  @include flex-row;
  justify-content: space-between;

  &::before, &::after {
    content: '';
    position: absolute;
    height: 40px;
    pointer-events: none;
    z-index: 3;
  }

  &::before {
    left: 0;
    width: 90px;
    background: linear-gradient(90deg, #FDFDFD 18.23%, rgba(217, 217, 217, 0.00) 100%);
  }

  &::after {
    right: 0;
    width: 150px;
    background: linear-gradient(270deg, #FDFDFD 18.23%, rgba(217, 217, 217, 0.00) 100%);
  }

  a, a:link, a:visited, a:focus, a:hover, a:active {
    color: $color-text-secondary;
  }

  .share-button {
    z-index: 4;
    margin-right: 25px;
  }
}