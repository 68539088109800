// This will be removed when redesign is completed
@font-face {
  font-family: Helvetica Neue;
  src: url('../../assets/fonts/HelveticaNeueBold.ttf') format('truetype'),
  url('../../assets/fonts/HelveticaNeueBold.woff2') format('woff2');
}
@font-face {
  font-family: Clattering;
  src: url('../../assets/fonts/Clattering.ttf') format('truetype'),
  url('../../assets/fonts/Clattering.woff2') format('woff2');
}
@font-face {
  font-family: Poppins Bold;
  src: url('../../assets/fonts/Poppins-Bold.ttf') format('truetype'),
  url('../../assets/fonts/Poppins-Bold.woff2') format('woff2');
}
@font-face {
  font-family: Poppins Light;
  src: url('../../assets/fonts/Poppins-Light.ttf') format('truetype'),
  url('../../assets/fonts/Poppins-Light.woff2') format('woff2');
}

@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.list {
  width: 100%;
  border: unset;
  height: 482px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  position: relative;
  border-top: 1px solid $gainsboro;
  padding-left: 15px;
  @include min-breakpoint-tablet {  

    height: 493px;
    padding-left: 25px;
    border-radius: 8px 0px 0px 8px;
  }

  .horizontal-div {
    @include flex-row;
    width: inherit;
    height: 60px;
    justify-content: space-between;
    @include min-breakpoint-tablet {
      height: 74px;
    }

    .div-title {
    @include flex-row;
    height: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
    @include min-breakpoint-tablet {
      width: 80%;
    }}

    .div-icons {
    @include flex-row;
    height: inherit;
    width: 30%;
    margin-right: 16px;
    justify-content: flex-end;
    @include min-breakpoint-tablet {
      width: 20%;
      margin-right: 25px;}
    }
  }

  .trending-div {
    @include flex-row;
    width: inherit;
    height: 45px;
    justify-content: flex-start;
    align-items: center;
  }

  .swiper-container::before {
    @include min-breakpoint-tablet { 
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 165px;
      background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
      pointer-events: none;
      z-index: 3;
  }
}
.swiper-container {
  margin-top: 20px;
}

  .swiper-button-prev , .swiper-button-next {
    display: none;
    @include min-breakpoint-tablet {
    display: inline-flex;
    width: 45px;
    border-radius: 50%;
    border: 1px solid $black;
    height: 45px;
    z-index:4;
    background-color: #FCFCFC;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15) !important;
    color:black;
    position: absolute;
    top: 38%;
    cursor: pointer;
    right: 50px;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    }
  }

  .swiper-button-prev {
  left: 7px  
  }
  .swiper-button-next {
  right: 25px ;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    font-weight: 400;
    font-size: 19px;
    color: $black;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled  {
    display: none;
  }
  
  .container {
    margin-left: 20px;
    display: flex;
    margin-top: 3px;
    width: max-content;
    transform: translateX(0px);
    transition: all 1s ease;
  }

  ul.swiper-wrapper {
    padding:0%;
  }

  .swiper-slide {
    margin-right: 15px !important;
    width: 330px !important;
    height: 100%;
  }
  
  //classname used for placeholders
  .carousel-desktop {
    display: flex;
    flex-direction: row;
    // flex: 1;
    gap: 15px;
    margin-top: 15px;
  }
  .carousel-desktop > .card-container {
    min-width: 330px;
  }
}