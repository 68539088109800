@import 'variables';
@mixin min-breakpoint-mobile {
  @media (min-width: #{$breakpoint-mobile}) {
    @content;
  }
}
@mixin max-breakpoint-mobile-max {
  @media (max-width: #{$breakpoint-mobile-max}) {
    @content;
  }
}
@mixin min-breakpoint-tablet {
  @media (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}
@mixin max-breakpoint-tablet {
  @media (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}
@mixin min-breakpoint-laptop {
  @media (min-width: #{$breakpoint-laptop}) {
    @content;
  }
}
@mixin max-breakpoint-laptop {
  @media (max-width: #{$breakpoint-laptop}) {
    @content;
  }
}
@mixin flex-row() {
  display: flex;
  flex-direction: row;
  align-items: center;
  @content;
}
@mixin flex-col() {
  display: flex;
  flex-direction: column;
}
@mixin bordered-container {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  padding: $container-gutter-desktop;
  border-radius: 10px;
  border: 1px solid $gainsboro;
}

@mixin body-text-1 {
  font-family: $font-family-primary;
  color: $color-text-primary;
  font-size: $size-14;
  line-height: $size-18;
  font-weight: 400;
  font-style: normal;
}
@mixin label-1 {
  @include body-text-1;
  font-size: $size-13;
  line-height: normal;
  @include min-breakpoint-tablet {
    font-size: $size-14;
  }
}
@mixin label-2 {
  @include body-text-1;
  font-size: $size-11;
  line-height: normal;
  @include min-breakpoint-tablet {
    font-size: $size-13;
  }
}
@mixin label-3 {
  @include body-text-1;
  font-size: $size-10;
  line-height: normal;
  @include min-breakpoint-tablet {
    font-size: $size-12;
  }
}
@mixin label-4 {
  @include min-breakpoint-tablet {
    @include body-text-1;
    font-size: $size-16;
    line-height: normal;
  }
}
// primary button
@mixin button {
  width: 100%;
  font-family: $font-family-primary;
  font-size: $size-16;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 5px;
  color: $color-primary-contrast;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 12px 24px 11px;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  transition: background-color .2s, box-shadow .2s, color .2s, transform .2s;
  &:hover {
    background-color: $black;
    border: 1px solid $timber-green;
    color: $color-primary-contrast;
  }
  &:focus-visible { 
    background-color: $black;
    border: 1px solid $timber-green;
  }
  &:disabled {
    background-color: $osloGrey;
    border: 1px solid $osloGrey;
  }
}
@mixin button-secondary {
  @include button;
  background-color: $timber-green;
  border: 1px solid $timber-green;
  &:hover {
    background-color: $color-primary;
    border: 1px solid $color-primary;
  }
  &:focus-visible { 
    background-color: $osloGrey;
    border: 1px solid $osloGrey;
  }
}

@mixin button-alert {
  @include button;
  background-color: $color-alert;
  border: 1px solid $color-alert;
  
  &:hover {
    background-color: $color-alert;
    border: 1px solid $color-alert;
  }
  &:focus-visible { 
    background-color: $osloGrey;
    border: 1px solid $osloGrey;
  }
}
@mixin fixed-button {
  @include button;
  width: 100%;
  height: $fixed-button-height;
  position: fixed;
  bottom: 0;
  border-radius: 0px;
  font-size: $size-18;
  z-index: 1;
}
@mixin highlighted {
  color: $color-primary;
  font-weight: bold;
}
@mixin link {
  color: $color-primary;
  text-decoration: underline;
}