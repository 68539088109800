.link-preferences {
    color: black !important;
    font-weight: bold !important;
  }
  
  .notfound--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 65%;
    min-width: 100%;
    gap: 10px;
  }

  .notFoundContent {
    width: 70%;
  }
  
  .click-here {
    color: black !important;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
  }