@import '../../../common/variables.scss';
@import '../../../common/mixins.scss';

$breakpoint-mobile: 768px;

.trending-list {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;

  .trending-text {
    min-width: 60px;
  }

  .trending-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .trending-list-item {
    margin: 0 3px;
    padding: 7px 15px ;
    background: $white;
    border: 1px solid $gainsboro;
    border-radius: 20px;
    min-height: 28px ;
    @include min-breakpoint-tablet {
      padding: 10px 15px ;
    }
  }

  a {
    color: $color-text-primary;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}