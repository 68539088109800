// Typography
$font-family-primary: 'Roboto', sans-serif;
$font-family-heading: 'Roboto', sans-serif;
$font-size-default: 16px;

// Breakpoints
$breakpoint-mobile: 414px;
$breakpoint-mobile-max: 540px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1200px;

// Layout
$page-gutter-mobile: 25px;
$container-gutter-desktop: 40px;
$navbar-height: 130px;
$fixed-button-height: 125px;

// Palette
$black:#141414;
$white: #FFF;
$grey: #485459;
$blue: #00ADEC;
$timber-green: #1A292F;
$osloGrey: #878E91;
$gainsboro: #DADCE0;
$lightGray: #F8F8F8;
$red: #FF000D;

// Theme
$color-primary: $blue;
$color-primary-contrast: $white;
$color-background: $white;
$color-text-primary: $black;
$color-text-secondary: $osloGrey;
$color-alert: $red;

// Sizes
$size-08: 0.5rem;
$size-10: 0.625rem;
$size-11: 0.6875rem;
$size-12: 0.75rem;
$size-13: 0.8125rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-17: 1.0625rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-28: 1.75rem;
$size-32: 2rem;
$size-35: 2.1875rem;
$size-38: 2.375rem;