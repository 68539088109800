@import '../../../common/variables.scss';
@import '../../../common/mixins.scss';

.tab-bar {
  background-color: $color-background;
  height: 40px;
  @include flex-row;
  justify-content: space-between;

  a, a:link, a:visited, a:focus, a:hover, a:active {
    color: $color-text-primary;
  }
}