@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.page-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin-top: $navbar-height;
  @include max-breakpoint-tablet {
    margin-top:  115px;
  }
}

.home-page,
.static-page,
.search-page,
.settings-page,
.signup-page,
.profile-page,
.error-page,
.category-page,
.article-page,
.section-page,
.advanced-search {
  background-color: $white;
  font-family: $font-family-primary;
  font-size: $font-size-default;
}

.settings-page,
.signup-page,
.static-page,
.page-not-found {
  padding: 0 $page-gutter-mobile;
}

.settings-page,
.error-page,
.static-page {
  margin-top: 30px;
  @include min-breakpoint-tablet {
    margin-top: 60px;
  }
}

.standard-layout {
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  width: 100%;

  main {
    width: auto;
  }

  .bordered-container {
    margin-top: 30px;
    @include min-breakpoint-tablet {
      @include bordered-container;
    }
  }

  .highlighted {
    @include highlighted;
  }

  .btn {
    @include button;
  }

  .carousel-div>.list {
    border: 1px solid $gainsboro;
    border-radius: 10px;
  }
}

.absolute {
  border-style: none !important;
}

.hidden {
  display: none !important;
}

.ad-placeholder {
  width: 320px;
  height: 70px;
  margin: auto;
  padding-bottom: 10px;
}

.ad-placeholder::before {
  content: "ADVERTISEMENT";
  color: grey;
  font-size: 10px;
  left: 40%;
  position: relative;
}

// modal
.MuiModal-root {
  .highlighted {
    @include highlighted;
  }
}