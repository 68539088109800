@import '../../../common/mixins.scss';
@import '../../../common/variables.scss';

.page-not-found {
  .content-text {
    margin-bottom: 20px;
    color: $color-text-secondary;

    & p {
      margin-bottom: 20px;
    }
  }
  .link {
    @include link;
  }
}