$breakpoint-mobile: 768px;

.category-page {
  main {
    .desktop {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
    }
  }
}
.circularLoading {
  align-items: center !important;
  justify-content:center !important;
  display: flex !important;
}

@media (max-width: $breakpoint-mobile) {
  .category-page {
    main {
      .mobile {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}