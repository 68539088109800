@import '../../common/mixins.scss';
@import '../../common/variables.scss';

.static-page {
  margin-bottom: 30px;
  main {
    text-align: left;
    min-height: unset;
    max-width: 1080px;
    margin: 0 auto;
  }

  .static-content {
    color: $grey;
    .a, a:link, a:visited, a:focus, a:hover, a:active {
      text-decoration: underline;
      color: $color-primary;
    }
    h4 {
      margin-top: 20px;
    }
    p,
    address {
      margin: 15px 0;
    }
    ul li {
      list-style: disc;
      margin: 5px 16px;
      &::marker {
        color: $color-primary;
      }
    }
    address {
      font-style: normal;
    }
    .underlined {
      text-decoration: underline;
    }

    .card:first-of-type {
      margin-top: 20px;
    }
    .card-icon {
      vertical-align: middle;
    }
    .social-icon {
      user-select: none;
      margin: 4px;
      margin: 4px 10px 4px 0;
      padding: 8px 16px;
      border-radius: 4px;
      box-sizing: content-box;
      color: rgb(255, 255, 255);
    }
    .twitter {
      background-color: #1DA1F2;
    }
    .instagram {
      background-color: #E4405F;
    }
    .facebook {
      background-color: #1877F2;
    }
  }
}