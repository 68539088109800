//Login Radius overrides
@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.regwall-box {
  max-width: 778px;
  border: 1px solid #dadce0 !important;
  border-radius: 8px;
  padding: 14px;
}
.regwall-box:before,
.regwall-box:after  {
  border-style: none !important;
  height: 100%;
}
.regwall-box__logo {
  margin: 30px 0 0 30px;
  right: 290px;
}
.regwall-box__content {
  align-items: start;
  margin-top: 8rem;
  font-family: $font-family-primary;
  font-style: normal;
  font-size: $size-16;
  font-weight: normal;
  br {
    display: none;
  }
  & > div {
    text-align: left;
    svg {
      display: none;
    }
    span {
      visibility: unset;
      margin: unset;
    }
    & > p:last-of-type {
      color: $color-text-primary !important;
      text-align: center;
    }
  }
}
.check-email {
  text-align: left;
  & > p {
    color: $color-text-primary !important;
  }
  & > p:last-of-type {
    text-align: center;
    margin-bottom: 10px;
  }
}

//sign-in title
.check-email .check-email__text--title {
  margin-top: 8px;
  font-size: $size-16 !important;
  text-align: left;
  @include min-breakpoint-tablet {
    font-size: $size-18 !important;
  }
}
.check-email::before {
  content: 'Sign in';
  font-family: $font-family-heading;
  font-style: normal;
  font-size: $size-24;
  line-height: normal;
  @include min-breakpoint-tablet {
    font-size: $size-38;
  }
}

// magic link title
.regwall-box {
  h3:first-of-type {
    font-family: $font-family-heading;
    font-style: normal;
    font-size: $size-24;
    font-weight: 400;
    line-height: normal;
    padding: unset;
    text-align: left;
    @include min-breakpoint-tablet {
      font-size: $size-38;
    }
  }
  h4 {
    font-family: $font-family-heading;
    font-size: $size-16;
    text-align: left;
    @include min-breakpoint-tablet {
      font-size: $size-18;
    }
  }
}

//change profile title 
.complete-profile__text {
  font-size: $size-16 !important;
  @include min-breakpoint-tablet {
    font-size: $size-18 !important;
  }
}
.complete-profile__text::before {
  content: 'Change Profile';
  display: block;
  font-family: $font-family-heading;
  font-style: normal;
  font-size: $size-24;
  line-height: normal;
  @include min-breakpoint-tablet {
    font-size: $size-38;
  }
}

// sign-up complete title 
.signup-complete__text:first-of-type {
  font-size: $size-24;
  @include min-breakpoint-tablet {
    font-size: $size-38;
  }
}
.auth-ui_resend-email__text {
  text-align: left !important;
}

//buttons
.button--solid {
  margin-left: unset !important;
  margin-right: unset !important;
  @include button;
}
.button--outline {
  margin-left: unset !important;
  margin-right: 16px !important;
  @include button-secondary;
}

//input and label
.form__input {
  font-size: $size-14;
  padding: 10px 0;
  height: unset;
  border: 0px;
  border-bottom: 1.5px solid $gainsboro;
  border-radius: 0px;
  @include min-breakpoint-tablet {
    font-size: $size-18;
  }
  &:focus {
    border-bottom: 1.5px solid $color-primary;
  }
  &:read-only {
    color: $color-text-primary;
  }
}
.form__label, .form__hint {
  color: $color-text-secondary;
  font-size: $size-14;
  font-weight: 400;
}
label.form__label[for=password] {
  margin-left: unset !important;
}
div[htmlfor=email] {
  color: $color-text-secondary;
  font-size: $size-14;
  font-weight: 400;
}

//consent fields
.consent-fields__checkbox-group > p {
  max-width: unset !important;
  margin-top: 17px;
  padding: unset !important;
  color: $color-text-primary !important;
  font-size: $size-11 !important;
  line-height: normal !important;
  @include min-breakpoint-tablet {
    font-size: $size-14 !important;
    font-weight: 300;
  }
}
.consent-fields__link {
  color: $color-primary !important;
  text-decoration: underline !important;
}

//'or sign-in with' 
.auth-ui-social {
  justify-content: start;
}
.signin__magic-link-or {
  text-align: left;
  margin-top: unset;
  font-size: $size-16;
  color: $color-text-primary !important;
}

//form
.signin__keep-me-and-forgot {
  justify-content: end;
  .signin__forgot-password > a {
    color: $color-primary;
  }
}
.form-group.form-group--expand {
  margin-left: unset !important;
}
.form-error:not(.form-error--last) {
  padding: 8px 0;
}

.auth-ui-social__button--google {
  margin: unset;
}
.form--column {
  & > p:last-of-type {
    color: $color-text-primary !important;
    text-align: center;
    margin-bottom: 10px;
  }
}
.showPassword {
  bottom: 35px;
}