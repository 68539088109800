@import '/src/common/mixins.scss';
@import '/src/common/variables.scss';

.overlay--container {
  position: absolute;
  top: 0;
  left: 0;
  z-index:9;
  backdrop-filter: blur(3px);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }


.categorypage-overlay {
  position: absolute;
  top:250px;
  left:unset;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color:  #158EE5;
  border-radius: 7px;
  height: 300px;
  width: 40%;
  color: white;
  @media (max-width: 1000px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
    top: 100px;
    left: unset;
}
}
.overlay {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #158EE5;
  border-radius: 7px;
  height: 65%;
  width: 50%;
  color: white;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20%;
    }
}
.teaser {
  flex-wrap: wrap;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text {
  flex-wrap: wrap;
  padding: 15px 184px 15px 184px;
  display: flex;
  align-items: center;
  text-align: center;
  @media (min-width: 1400px) {
  padding: 20px 54px 20px 54px;
  }
  @media (max-width: 1400px) {
  padding: 15px 114px 15px 114px;
  }
  @media (max-width: 1200px) {
  padding: 15px 84px 10px 84px;
  font-size: 17px;
  }
  @media (max-width: 900px) {
  padding: 15px 54px 15px 54px;
  font-size: 17px;
  }
  @media (max-width: 768px) {
  padding: 8px 24px 8px 24px;
  font-size: 16px;
  }
}
.overlay--btn {
  @include button;
  background-color: white;
  color: $blue;
  width: 217px;
}