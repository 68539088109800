@import "src/common/mixins.scss";

.divTitle {
  @include flex-row();
  height: 74px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.divTitle:hover > .editIcon {
  display: flex;
}
