@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.signup-page {
  .top-container {
    @include flex-row;
    justify-content: space-between;
    height: 60px;
    margin-bottom: 25px;

    p { text-align: right; }

    b {
      color: $color-text-primary;
      font-weight: 700;
      font-family: $font-family-primary;
    }

    .sign-in-button {
      font-size: $size-14;
      vertical-align: baseline;
    }
  }

  h1 {
    margin-bottom: 12px;
  }

  .content-text {
    margin-bottom: 20px;
    color: $color-text-secondary;

    & p {
      margin-bottom: 20px;
      &:has(strong) {
        margin-bottom: 0;
      }
    }
  }

  .form-container {
    margin: 25px 0 20px;
  }

  .link {
    @include link;
  }

  .flex-btn {
    @include flex-row;
    justify-content: center;
    padding-right: unset;
    padding-left: unset;
    margin-top: 40px;
    
    .btn {
      @include fixed-button;
      @include min-breakpoint-tablet {
        height: unset;
        position: unset;
        bottom: unset;
        border-radius: 5px;
        font-size: $size-16;
        z-index: unset;
      }
    }
  }
}