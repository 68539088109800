@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.settings-page {
  margin-bottom: 25px;
  
  .Mui-selected {
    font-weight: bold;
  }

  .settings-tab {
    color: $color-text-secondary;
    line-height: normal;
    padding: 8px 28px 10px 0;
    @include min-breakpoint-tablet {
      font-size: 16px;
      padding: 10px 32px 17px 0;
    }
  }

  .flex-row {
    @include flex-row;
    justify-content: space-between;
  }

  .label {
    color: $color-text-secondary;
  }
}