@import '../../common/mixins.scss';

.profile-page {
  overflow: hidden;
  height: 100%;
  width: auto;

  .change-profile {
    @include flex-row;
    justify-content: space-between;
    margin: 20px 0;
  
    .link-button {
      font-size: $size-16;
    }
  }

  .delete-profile {
    border-top: 1px solid $gainsboro;
    padding-top: 30px;
    gap: 40px;
    @include flex-col;
    @include min-breakpoint-tablet {
      @include flex-row;
    }
  }

  .btn-alert {
    @include button-alert;
  }
}

.password-strength__checkbox-group {
  align-items: center !important;
}

.MuiDialog-paperWidthSm {
  width: 600px;
  max-width: 600px;
}