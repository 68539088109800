@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.embed-page-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.embed-layout {
  overflow: hidden;
  height: min-content;
  width: 100%;
  margin-top: 0px !important;
  margin-bottom: 24px;

  .search--wrapper {
    gap: 10px;

    .results--wrapper .search--card .search--img-container {
      width: auto;
      max-width: 330px;
      @include max-breakpoint-mobile-max {
      width: 100%;
      max-width: unset;
    }
    }
  }

  .search--form {
  width: 100%;
  background-color: $white;
  height: 70px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @include max-breakpoint-tablet {
    gap: 3px;
  }
  }

  .search--button {
    @include button();
    margin-top: 25px;
  }

  .pagination {
    @include flex-row();
    justify-content: center;
    margin-top: 10px;
  }
}
