@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.card-container {
  @include flex-col;
  min-width: inherit;
  height: 350px;
  transform: translateZ(0);
  overflow: hidden;
  position: relative;
  .react-card-flip {
    width: 100%;
    height: 100%;
  }
  .react-card-flipper {
    @include flex-col;
    justify-content: space-between;
    height: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }
  .react-card-front,
  .react-card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .teaser-front {
    @include flex-col;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
  }

  .image-container {
    flex: 1;
    width: 100%;
    height: 181px;
    img {
      width: 100%;
      height: 181px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .details-container {
    @include flex-row;
    width: 100%;
    min-height: 30px;
    padding-left: 5px;
    .details {
      color: $osloGrey;
      opacity: 1;
      line-height: 18px;
    }
  }
  .content-container {
    width: 100%;
    height: 100%;
    min-height: 96px;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    .content-title {
      color: $black;
      text-decoration: none;
      opacity:1;
    }
  }
  .toolbar-container {
    @include flex-row;
    justify-content: space-between;
    width: 100%;
    max-height: 30px;
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }
        
    
  .brand-logo {
    display: flex;
    align-items: center;
    max-width: 25px;
    max-height: 25px;
    border-radius: 50%;
    margin-right: 5px;
    object-fit: contain
  }

  .teaser-back {
    @include flex-col;
    width: auto;
    height: 100%;
    border-radius: 10px;
    border: 1px solid $gainsboro;
  }
}