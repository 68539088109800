@import '../../common/variables.scss';
@import '../../common/mixins.scss';

// CMP fonts
@font-face {
  font-family: Helvetica Neue;
  src: url('../../assets/fonts/HelveticaNeueBold.ttf') format('truetype'),
       url('../../assets/fonts/HelveticaNeueBold.woff2') format('woff2');
}

.footer {
  background-color: $timber-green;
  padding: 0 $page-gutter-mobile;
  min-height: 77px;
  @include min-breakpoint-tablet {
    @include flex-row;
    justify-content: space-between;
  }

  .footer-links {
    @include flex-col;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 32px 0;
    @include min-breakpoint-tablet {
      @include flex-row;
      padding: unset;
    }
  }

  .footer-logo {
    @include flex-col;
    align-items: flex-end;

    .mna-logo {
      max-width: 262px;
      height: auto;
    }

    .mna-icon {
      max-width: 50px;
      height: auto;
    }
  }
}


.copyright {
  @include flex-row;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: $timber-green;
  padding: 15px $page-gutter-mobile;

  .mna-icon {
    max-width: 38px;
    height: auto;
  }
}

.copyright-text {
  color: $color-primary-contrast;
  line-height: $size-12;
  small {
    font-size: $size-12;
  }
}

.uplifted-footer {
  margin-bottom: $fixed-button-height;
}