@import '../../../common/mixins.scss';
@import '../../../common/variables.scss';

.share-button-dialog {
  & .MuiDialog-paper {
    border-radius: 10px;
    box-shadow: 20px 0px 44px 0px rgba(0, 0, 0, 0.25);
    max-width: 452px;
  }

  .copy-link {
    @include flex-row;
    gap: 18px;
  
    .inline-logo {
      padding: 8px;
      border: 1px solid $gainsboro;
      max-height: 43px;
  
      img {
        width: 25px;
      }
    }
  
    .copy-title {
      max-width: 300px;
      height: 20px;
      overflow: hidden;
    }
  
    &::after {
      content: '';
      position: absolute;
      width: 230px;
      height: 42px;
      pointer-events: none;
      z-index: 3;
      right: 0;
      background: linear-gradient(270deg, #FFF 64.06%, rgba(255, 255, 255, 0.00) 100%);;
    }
  
    .copy-link-button {
      font-size: $size-16;
      text-transform: none;
      position: absolute;
      z-index: 4;
      right: 24px;
    }
  }

  .divider {
    height: 1px;
    border: none;
    background-color: $gainsboro;
    margin: 20px 0;
    padding: 0;
  }
}