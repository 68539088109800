@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.parent-node {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  &:first-child {
    border-top: none !important;
  }
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .open-node {
    @include highlighted;
    .MuiListItemText-primary {
      font-weight: bold;
    }
  }
}

.chip-container {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  .MuiChip-root {
    font-size: $size-12;
    background-color: $gainsboro;
  }

  .chips {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    overflow-y: hidden;
    overflow-x: hidden;
    height: inherit;
  }

  .chips-span {
    display: flex;
    align-items: center;
    height: inherit;
    padding-left: 5px;
    align-self: center;
    width: 15%;
    cursor: pointer;
    @media (max-width: 414px) {
      width: 25%;
    }
  }
}

.chips-expanded {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 30px;
  overflow: auto;
  height: inherit;
}


.tree-item-group {
  height: 350px;
  overflow: auto;
  list-style-type: none;
  padding-inline-start: 0px;
  @media (min-width: 414px) {
    height: 500px;
  }
}