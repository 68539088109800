@import '../../common/mixins.scss';
@import '../../common/variables.scss';

.breadcrumbs-and-sharing-container {
  display: none;
  @include min-breakpoint-tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $page-gutter-mobile;
    height: 50px;
    margin-bottom: 7px;
  }
  
  .breadcrumbs-container {
    flex-grow: 1;
    text-align: left;

    .breadcrumb {
      color: $color-text-primary;
    }

    .breadcrumb-active {
      color: $color-primary;
    }
  }

  .trending-container {
    width: calc(100% - 30px); 
  }

  .sharing-container {
    flex-shrink: 0;
    text-align: right;
  }
}
