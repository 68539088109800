@import '../../../common/mixins.scss';
@import '../../../common/variables.scss';

.share-container {
  .share-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 20%;
    & > * {
      @include flex-col;
      flex: 20%;
      align-items: center;
    }
  }

  a {
    color: $color-text-primary;
    &:hover {
      color: $color-primary;
    }
  }

  i.fab.fa-facebook {
    color: #3b5998;
    font-size: 42px; /* Default font size */
  }

  i.fab.fa-facebook:hover {
    color: #002d55;
  }

  i.fab.fa-facebook-messenger {
    color: #0084ff;
    font-size: 42px; /* Default font size */
  }
  
  i.fab.fa-facebook-messenger:hover {
    color: #0063cc;
  }

  i.fab.fa-twitter {
    color: #1da1f2;
    font-size: 42px; /* Default font size */
  }

  i.fab.fa-twitter:hover {
    color: #0069b4;
  }

  i.fab.fa-linkedin {
    color: #0e76a8;
    font-size: 42px; /* Default font size */
  }

  i.fab.fa-linkedin:hover {
    color: #006699;
  }

  i.fab.fa-pinterest {
    color: #bd081c;
    font-size: 42px; /* Default font size */
  }

  i.fab.fa-pinterest:hover {
    color: #8c0615;
  }

  i.far.fa-envelope {
    color: #e74c3c;
    font-size: 42px; /* Default font size */
  }

  i.far.fa-envelope:hover {
    color: #c0392b;
  }

  i.fab.fa-whatsapp {
    color: #25d366;
    font-size: 42px; /* Default font size */
  }

  i.fab.fa-whatsapp:hover {
    color: #1da851;
  }

  /* Custom Nextdoor Icon Placeholder */
  i.fab.fa-nextdoor {
    /* Add your custom styles for the Nextdoor icon */
    color: #999999;
    font-size: 42px; /* Default font size */
  }

  i.fab.fa-nextdoor:hover {
    /* Add your custom hover styles for the Nextdoor icon */
    color: #777777;
  }

  .share-text {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  @include max-breakpoint-tablet {
    i.fab.fa-pinterest {
      display: none; /* Hide pinterest on mobile */
    }

    i.fab.fa-facebook,
    i.fab.fa-facebook-messenger,
    i.fab.fa-twitter,
    i.fab.fa-linkedin,
    i.far.fa-envelope,
    i.fab.fa-whatsapp,
    i.fab.fa-nextdoor {
      font-size: 30px; /* Updated font size for breakpoint mobile */
    }
  }
}