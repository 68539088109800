@import 'variables';
@import 'mixins';

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: $font-size-default;
}

body {
  font-family: $font-family-primary;
  background-color: $color-background;
  color: $color-text-primary;
}

a, a:link, a:visited, a:focus, a:hover, a:active {
  text-decoration:none;
  color: white;
}
