.empty--homepage {
  display: flex;
  border-radius: 8px;
  border: 1px solid #dadce0;
  width: 100vw;
  height: 100vh;
  justify-content:start;
  flex-direction: column;
  align-items: center;
}
.empty--container {
  text-align: center;
  padding: 15px;
  margin-top: 280px;
}