#mna-speech-bubble {
  position: fixed;
  bottom: 60px;
  right: 100px;
  z-index: 9999;
  max-width: 200px;
  padding: 10px;
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #cccccc;
  cursor: default;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 50%;
    margin-right: -10px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #ffffff transparent transparent transparent;
  }
}

.mna-speech-bubble-button {
  background-color: #24ADE4;
  color: #FFFFFF;
  padding: 8px 16px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
