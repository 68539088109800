.ad-placeholder-desktop { 
  width: 300px;
  height: 250px;
  margin: 30px 15px;
}

.ad-placeholder-desktop::before {
  content: "ADVERTISEMENT";
  color: grey;
  font-size: 10px;
  margin-top: 5px;
  top: 8px;
  left: 39%;
  position: absolute;
}